.about-background {
  width: 100%;
  min-height: 70vh; /* Use min-height instead of height to accommodate content */
  box-sizing: border-box;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  background-image: url('../Assets/parallax.png'); 
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.about-container {
  width: 80%; 
  max-width: 1200px; 
  background: #12372A;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(64, 0, 255, 0.3);
  padding: 2% 4%; 
  overflow: hidden;
}

.about-section {
  margin: 20px 0; 
  animation: fadeInUp 1s ease-out;
}

.about-section h2 {
  font-size: 2.5rem;
  color: #FFD700;
  margin-bottom: 1rem; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.about-section p {
  font-size: 1.2rem;
  color: #FFF;
  line-height: 1.8; 
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (max-width: 768px) {
  .about-container {
    width: 90%; /* Slightly increase width on mobile for better use of space */
    padding: 10% 5%; /* Increase padding percentage for responsive spacing */
  }

  .about-section h2 {
    font-size: 1.8rem; /* Further decrease font size for mobile */
  }

  .about-section p {
    font-size: 1rem; /* Ensure text is legible on smaller screens */
  }
}

@media (max-width: 480px) {
  .about-background {
    align-items: flex-start; /* Align content to the top on very small screens */
    padding-top: 20px; /* Add some padding at the top */
  }

  .about-container {
    width: 95%; /* Increase width to use more space on very small screens */
    padding: 10px; /* Adjust padding to be consistent */
  }

  .about-section h2 {
    font-size: 1.5rem; /* Smaller headings for very small screens */
  }

  .about-section p {
    font-size: 0.9rem; /* Smaller text for very small screens */
  }
}
