.action-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
  background-image: url('../Assets/Meet_the_team.png');
  background-color: rgba(255, 255, 255, 0.4);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  padding: 40px; 
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-height: 500px;
}

button {
  background-color: #12372A;
  color: #FBD85D;
  padding: 25px 50px; 
  font-size: 1.5rem; 
  font-weight: bold; 
  border-radius: 15px; 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); 
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

button:hover {
  background-color: #0F5630; 
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.welcome-message, .additional-info {
  color: #FFFFFF;
  margin: 20px 0; 
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.9); 
  background: rgba(0, 0, 0, 0.8);
  padding: 25px; 
  border-radius: 15px; 
  max-width: 80%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
  font-size: 1.2rem; 
  line-height: 1.5; 
}

.welcome-message:hover, .additional-info:hover {
  transform: translateY(-3px); 
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.35);
}
